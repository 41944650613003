import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <span className='email'><b>Email:</b> varmabrothers09@gmail.com <b>Contact:</b> +91 8291633206<br/></span>
      <span className='add'><b>Address:</b> MG Cross Road No. 1, beside Swami Vivekanand International School,Kandivali, Kandivali, Parekh Nagar, Kandivali West, Mumbai, Maharashtra-400067</span>
      <p>&copy; 2024 Varma Brothers. All rights reserved.</p>
    </footer>
  );
};

export default Footer;
{/* <span className='contact'><b>Contact US:</b> +91 8291633206</span> */}