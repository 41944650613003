import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from './components/Header';
import Footer from './components/Footer';
import Homepage from './components/Homepage';

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Homepage />} />
        <Route path='/Header' element={<Header />} />
        {/* <Route path='/Homepage' element={<Homepage />} /> */}
        <Route path='/Footer' element={<Footer />} />
       
      </Routes>
    </BrowserRouter>
  );
}


export default App;
