import React, { useState } from 'react';
import faqData from './Qna.json'; // Adjust path as necessary
import './Faq.css';

const Faq = () => {
  // State to keep track of the open category and question
  const [openCategory, setOpenCategory] = useState(null);
  const [openQuestion, setOpenQuestion] = useState(null);

  // Toggle open state of a specific category
  const toggleCategory = (index) => {
    setOpenCategory(openCategory === index ? null : index);
  };

  // Toggle open state of a specific question
  const toggleQuestion = (categoryIndex, questionIndex) => {
    const questionId = `${categoryIndex}-${questionIndex}`;
    setOpenQuestion(openQuestion === questionId ? null : questionId);
  };

  return (
    <div className='faq-container'>
      <span className='faq'>FAQs:</span>
      <div className='faq-list'>
        {faqData.faq.map((category, categoryIndex) => {
          const isCategoryOpen = openCategory === categoryIndex;
          return (
            <div key={categoryIndex} className={`faq-category ${isCategoryOpen ? 'open' : ''}`}>
              <h3 className="faq-category-title" onClick={() => toggleCategory(categoryIndex)}>
                {category.category}
              </h3>
              {isCategoryOpen && (
                <>
                  {category.questions.map((faq, questionIndex) => {
                    const isQuestionOpen = openQuestion === `${categoryIndex}-${questionIndex}`;
                    return (
                      <div key={questionIndex} className={`faq-item ${isQuestionOpen ? 'open' : ''}`}>
                        <div className="faq-question" onClick={() => toggleQuestion(categoryIndex, questionIndex)}>
                          <p><strong>Q:</strong> {faq.question}</p>
                        </div>
                        {isQuestionOpen && (
                          <div className="faq-answer">
                            <p> {faq.answer}</p>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Faq;
