import React from 'react';
import './Header.css';
import logo from '../assest/VarmaBrotherslogo.png';

const Header = () => {
  return (
    <header className="header">
      <div className="logo">
      <img src={logo} className="overlay" alt="logo" />
      </div>
      <nav>
        <ul>
          <li><a href="#About">About Us</a></li>
          <li><a href="#Contact">Contact</a></li>
          <li><a href="#Programs">Programs</a></li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
