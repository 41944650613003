// Homepage.js
import React, { useState, useRef } from 'react';
import './Homepage.css';
import backgroundImage1 from '../assest/image2.jpg';
import backgroundImage2 from '../assest/swimmingpool.png';
import BigHygienicPool from '../assest/Big_and_Hygienic_Pool.png';
import bestswimmingacademy from '../assest/bestswimmingacademy.png';
import academyforswimming from '../assest/academyforswimming.png';
import ExperiencedcoachingStaff from '../assest/ExperiencedcoachingStaff.png';
import Level1Goldfish from '../assest/Level1Goldfish.png';
import Level3Swordfish from '../assest/Level3Swordfish.png';
import Level2SeaTurtle from '../assest/Level2SeaTurtle.png';
import Level4Sailfish from '../assest/Level4Sailfish.png';
import facebookIcon from '../assest/Varma_brothers_facebook.png';
import instagramIcon from '../assest/Varma_brothers_instagram.png';
import VarmaBrotherslogo from '../assest/VarmaBrotherslogo.png';
import Footer from './Footer'; // Ensure the path is correct
import './Footer.css'; // Import the CSS for the footer
import Faq from './Faq.jsx';

const InfoSection = ({ backgroundImage, title, description, extraContent }) => (
  <div className="info">
    <div className="app-container">
      <header className="header1">
        <img src={VarmaBrotherslogo} alt="Logo" className="logo" />
      </header>
    </div>
    <img src={backgroundImage} className="overlay" alt="background" />
    <div className="content">
      <h1>{title}</h1>
      <b><p>{description}</p></b>
      {extraContent && <div className="extra-content">{extraContent}</div>}
    </div>
  </div>
);

const Card = ({ title, description, image }) => (
  <div className="f-card">
    <img src={image} alt={title} className="f-card-image" />
    <h3 className="f-card-title">{title}</h3>
    <p className="f-card-description">{description}</p>
  </div>
);

const Homepage = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: ''
  });

  const formRef = useRef(null);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    alert("Thank you for Enrolling with Varma Brothers");
    console.log('Form submitted', formData);
  };

  const scrollToForm = () => {
    formRef.current.scrollIntoView({ behavior: 'smooth' });
  };


  return (
    <div className="container">
      <InfoSection
        backgroundImage={backgroundImage1}
        title="MASTER THE ART OF SWIMMING WITH VARMA BROTHERS"
        description="Over 20 Years of Expertise at Country Club, Kandivali West"
        extraContent={<button className="enroll-btn" onClick={scrollToForm}>ENROLL NOW</button>}
      />

      <div className="s-container">
        <div className="text-section">
          <div className="text-adjustment">
            <h3>About Us</h3>
            <p>
              At Varma Brothers, we believe that swimming is not just a sport; it's a life skill that promotes fitness, confidence, and fun. Our academy is dedicated to providing a safe, supportive, and engaging environment for swimmers of all ages and skill levels.
            </p>
          </div>
          <div className="text-adjustment-t">
            <h3>Our Mission</h3>
            <p>
              Our mission is to inspire and empower individuals to embrace the water, enhance their swimming skills, and foster a lifelong love for the sport. We aim to cultivate a community of passionate swimmers through expert coaching and personalized training.
            </p>
          </div>
        </div>
        <div className="image-section">
          <img src={backgroundImage2} alt="Swimming Pool" />
        </div>
      </div>

      <div className="t-container">
        <div className="t-center">
          <span className='sw-pr'>Swimming Programs</span>
          <span className='what-we-text'>What We</span>
          <span className='what-we-text'>Offer</span>
        </div>

        <div className="cards-section">
          <div className="card">
            <div className="card-title">
              <h3>Level 1: Goldfish</h3>
            </div>
            <div className="card-image">
              <img src={Level1Goldfish} alt="Card Image" />
            </div>
            <div className="card-content">
              <p>Breathing techniques, floating, and gliding.</p>
            </div>
          </div>

          <div className="card">
            <div className="card-title">
              <h3>Level 2: Sea Turtle</h3>
            </div>
            <div className="card-image">
              <img src={Level2SeaTurtle} alt="Card Image" />
            </div>
            <div className="card-content">
              <p>Vertical balance, freestyle, and back gliding.</p>
            </div>
          </div>

          <div className="card">
            <div className="card-title">
              <h3>Level 3: Swordfish</h3>
            </div>
            <div className="card-image">
              <img src={Level3Swordfish} alt="Card Image" />
            </div>
            <div className="card-content">
              <p>Strong freestyle, backstroke, diving, and treading in deep water.</p>
            </div>
          </div>

          <div className="card">
            <div className="card-title">
              <h3>Level 4: Sailfish</h3>
            </div>
            <div className="card-image">
              <img src={Level4Sailfish} alt="Card Image" />
            </div>
            <div className="card-content">
              <p>Advanced strokes, diving, and underwater swimming.</p>
            </div>
          </div>
        </div>
      </div>

      <div className="benefits-container">
        <h1 className="benefits-title">BENEFITS OF JOINING VARMA BROTHERS</h1>
        <div className="benefits-grid">
          <div className="benefit">
            <img src={BigHygienicPool} alt="Big and hygienic pool" />
            <h3>BIG AND HYGIENIC POOL</h3>
          </div>
          <div className="benefit">
            <img src={bestswimmingacademy} alt="Flexible timings" />
            <h3>FLEXIBLE TIMINGS</h3>
          </div>
          <div className="benefit">
            <img src={ExperiencedcoachingStaff} alt="Experienced staff" />
            <h3>EXPERIENCED STAFF</h3>
          </div>
          <div className="benefit">
            <img src={academyforswimming} alt="Flexible timings" />
            <h3>REGULAR EVENTS</h3>
          </div>
        </div>
      </div>

      <div className="fi-container" ref={formRef}>
        <div className="fi-text-section">
          <span className='htgs'>How to get Started?<br/></span>
          <span className='htgs-q'>Are you ready to make a splash <br/>in the swimming world?</span>
          <div className="social-links">
            <h3>Follow Us</h3>
            <div className="social-icons">
              <a href="https://www.facebook.com/profile.php?id=61566759760624" target="_blank" rel="noopener noreferrer">
                <img src={facebookIcon} alt="Facebook" className="icon" />
              </a>
              <a href="https://www.instagram.com/varmabrothersswimming/" target="_blank" rel="noopener noreferrer">
                <img src={instagramIcon} alt="Instagram" className="icon" />
              </a>
            </div>
          </div>
        </div>
        <div className="fi-image-section">
          <div className="form-container">
            <form className="enquiry-form" onSubmit={handleSubmit}>
              <h2>Enquire Below</h2>
              <input
                type="text"
                name="firstName"
                placeholder="First Name"
                value={formData.firstName}
                onChange={handleChange}
              />
              <input
                type="text"
                name="lastName"
                placeholder="Last Name"
                value={formData.lastName}
                onChange={handleChange}
              />
              <input
                type="email"
                name="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleChange}
              />
              <input
                type="tel"
                name="phoneNumber"
                placeholder="Phone Number"
                value={formData.phoneNumber}
                onChange={handleChange}
              />
              <div className="disclaimer-container">
                <span className='radio'>
                  <input type="radio" id="disclaimer-accept" name="disclaimer" />
                </span> 
                <label htmlFor="disclaimer-accept" className="disclaimer-text">
                  Varma Brothers will retain your personal information and solely utilize it in connection with your inquiry.
                </label>
              </div>
              <button type="submit" className="enroll-button">Enroll Now</button>
            </form>
          </div>
        </div>
      </div>
      <Faq selectedCategory="General Questions" />
      {/* Add the Footer component here */}
      <Footer />

    </div>
  );
};

export default Homepage;